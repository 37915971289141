@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&family=Roboto:wght@100;300;400;500;700;900&display=swap");


@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

/* Assuming you have a class for the Accordion */
.mantine-accordion {
  --mantine-accordion-item-chevron-display: none !important;
}

.mantine-Accordion-chevron {
  display: none !important;
}

.mantine-Accordion-content {
  color: #667085;
  font-family: Cabin;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 1.35rem */
  letter-spacing: 0.00563rem;
}
